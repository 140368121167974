import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import logo from '../../assets/logo.svg';
import { StyledHeader } from '../../styles';

export default function Header() {
  const [lang, setLang] = useState('uz-cyrillic');
  useEffect(() => {
    const currentLang = window.location.pathname.replace(/\//g, '') || 'uz-cyrillic';
    setLang(currentLang);
  }, []);

  const langList = [
    { title: 'Ўзб', key: 'uz-cyrillic', },
    { title: "O'zb", key: 'uz', },
    { title: 'Рус', key: 'ru' },
  ];

  const selectLang = (e) => {
    const lang = e.target.value;
    if (lang === 'uz-cyrillic') {
      return window.location.href = window.location.origin + '/';
    }
    return window.location.href = window.location.origin + '/' + lang;
  };

  return (
    <StyledHeader>
      <div className="container">
        <Link to="/" id="logo">
          <img src={logo} alt="road24" height="30" />
        </Link>
        <select value={lang} style={style} onBlur={e => console.log(e.target)} onChange={selectLang}>
          {
            langList.map(item => {
              return (
                <option key={item.key} value={item.key}>
                  {item.title}
                </option>
              )
            })
          }
        </select>
      </div>
    </StyledHeader>
  )
}

const style = {
  appearance: "none",
  border: "none",
  background: "transparent",
  outline: "none",
  cursor: "pointer",
  width: 80,
  padding: "10px 15px",
  textAlign: "right",
  position: "relative",
  right: -40,
}
import React from 'react';

const Message = (props) => {
  const { data } = props;
  if (data.type) {
    return <span className="message success" style={style.success} dangerouslySetInnerHTML={{ __html: data.text }}></span>
  }
  return <span className={`message ${(data.text && !data.type) ? 'error' : ''}`} style={style.error}>{data.text}</span>
}

const style = {
  error: {
    color: 'red',
    fontSize: 14,
    lineHeight: 1.4,
    marginBottom: 10,
    display: 'inline-block',
  },
  success: {
    color: 'var(--green)',
    fontSize: 14,
    lineHeight: 1.4,
    marginBottom: 10,
    display: 'inline-block',
  }
}

export default Message;

import React from "react";
import PropTypes from "prop-types";

import "./layout.css";
import GlobalStyles from '../../styles/global';
import Footer from './Footer';
import Spinner from './Spinner';

const Layout = ({ children }) => {
  return (
    <>
      <Spinner />
      <GlobalStyles />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from 'react';
import logo from '../../images/icon.svg';
import { StyledFooter } from '../../styles';
import iconKashApp from '../../images/kash-app-white.svg';
import DownloadSection from './DownloadSection';
import { t } from '../../lang';

export default function Footer() {
  return (
    <StyledFooter>
      <div className="container">
        <img src={logo} alt="road24.uz" />
        <h1>{t("Road24 находится в разработке")}</h1>
        <DownloadSection id="footer" key="footer-section" />
        <a href="https://t.me/road24uz" id="telegram">{t("Следите за обновлениями Road24 в Telegram")}</a>
      </div>
      <div id="lower-footer">
        <img src={iconKashApp} alt="OOO Kash App" />
        <a href="https://kash.uz/">{t("developed_by")}</a>
      </div>
      <a href="/privacy-policy" style={{ margin: 10, height: 20, display: 'inline-block' }}>Privacy policy</a>
      <a href="/privacy-policy-yur" style={{ margin: 10, height: 20, display: 'inline-block' }}>Privacy policy (legal entity)</a>
      <a href="/privacy-policy-personal-data" style={{ margin: 10, height: 20, display: 'inline-block' }}>Privacy policy (personal data)</a>
    </StyledFooter>
  )
}

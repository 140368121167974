import React from 'react';
import { StyledHome } from '../../styles';
import Header from './Header';
import DownloadSection from './DownloadSection';
import orgIIB from '../../images/orgs/iib.png';
import orgGai from '../../images/orgs/gai.svg';
import orgTrans from '../../images/orgs/trans-short.png';
import orgTosh from '../../images/orgs/toshkent.png';
import imgPhone from '../../images/handbook.png';
import { t } from '../../lang';

export default function Main(props) {

  return (
    <StyledHome id="hero-area">
      <Header />
      <div className="container">
        <div className="col-left">
          <h1>{t("Проверка и оплата штрафов")}</h1>
          <DownloadSection key="main-input" />
          <h4 className="org-title">{t('При поддержке')}</h4>
          <div id="organizations" className="organizations">
            <div>
              <a href="#1">
                <img src={orgIIB} alt={t("Министерство Внутренних Дел")} />
                <span>{t("Министерство Внутренних Дел")}</span>
              </a>
            </div>
            <div>
              <a href="#2">
                <img src={orgGai} alt={t("Главное управление безопасности дорожного движения")} />
                <span>{t("Главное управление безопасности дорожного движения")}</span>
              </a>
            </div>
            <div>
              <a href="#3">
                <img src={orgTosh} alt={t("Хокимят города Ташкента")} />
                <span>{t("Хокимят города Ташкента")}</span>
              </a>
            </div>
            <div>
              <a href="#4">
                <img src={orgTrans} alt={t("MInstry of transport")} />
                <span>{t("Министерство транспорта Республики Узбекистан")}</span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-right" id="home-phone">
          <div id="frame">
            <div className="inside"></div>
          </div>
          <img src={imgPhone} alt="road24" className="app-preview" />

          <h4 className="org-title">{t('При поддержке')}</h4>
          <div id="organizations-below" className="organizations">
            <div>
              <a href="#1">
                <img src={orgIIB} alt={t("Министерство Внутренних Дел")} />
                <span>{t("Министерство Внутренних Дел")}</span>
              </a>
            </div>
            <div>
              <a href="#2">
                <img src={orgGai} alt={t("Главное управление безопасности дорожного движения")} />
                <span>{t("Главное управление безопасности дорожного движения")}</span>
              </a>
            </div>
            <div>
              <a href="#3">
                <img src={orgTosh} alt={t("Хокимят города Ташкента")} />
                <span>{t("Хокимят города Ташкента")}</span>
              </a>
            </div>
            <div>
              <a href="#4">
                <img src={orgTrans} alt={t("MInstry of transport")} />
                <span>{t("Министерство транспорта Республики Узбекистан")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </StyledHome>
  )
}

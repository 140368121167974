import React from 'react';
import { StyledSection } from '../../styles';
import PropTypes from 'prop-types';

function Section(props) {
  const { title, text, img, bg, list, id, titleImg = [], textImg = [] } = props;

  return (
    <StyledSection img={img} className={bg ? 'background-light' : ''} id={id}>
      <div className="container">
        <div className="col-left">
          <h1>
            {title}
            {
              titleImg.map(item => <img src={item} key={item} className="title-image" alt={title} />)
            }
          </h1>
          <div className="sub-title">
            {text}
            {
              textImg.map(item => <img src={item} key={item} className="title-image" alt={title} />)
            }
          </div>
          {list &&
            (
              <ul>
                {list.map(item => <li key={item}>{item}</li>)}
              </ul>
            )
          }
        </div>
        <div className="col-right">
          <img src={img} alt="road24" className="app-preview" />
        </div>
      </div>
    </StyledSection>
  )
}

Section.defaultProps = {
  title: '',
  text: '',
  img: '',
  bg: false,
  list: [],
}

Section.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
  bg: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.string),
}
export default Section;

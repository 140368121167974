import React, { useEffect, useState } from 'react';
import spin from '../../images/spinner.svg';

export default function Spinner() {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    setTimeout(() => setVisible(false), 1000);
  }, []);

  return (
    <div style={{ ...style.wrapper, display: visible ? 'flex' : 'none' }}>
      <img src={spin} style={style.img} alt="spinner" />
    </div>
  )
}

const style = {
  wrapper: {
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: "1000",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    width: "100px",
  }
}